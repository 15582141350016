import './App.css';
import RecommendedForm from './components/RecommendedForm';
import Box from '@mui/material/Box';
import { Routes, Route } from "react-router-dom"
import NotFound from './components/NotFound';

function App() {
  return (
    <Box className="App" sx={{ paddingBottom: '80px' }}>
      <Routes>
        <Route path="/" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/:id_sucursal" element={<RecommendedForm />} />
      </Routes>
    </Box>
  );
}

export default App;
