import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import './recommendedForm.scss';
import axios from 'axios';
import ProductCard from './ProductCard';
import { useParams } from 'react-router-dom';
import NotFound from './NotFound';

var Fn = {
    // Valida el rut con su cadena completa "XXXXXXXX-X"
    validaRut: function (rutCompleto) {
        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto))
            return false;
        var tmp = rutCompleto.split('-');
        var digv = tmp[1];
        var rut = tmp[0];
        if (digv == 'K') digv = 'k';
        return (Fn.dv(rut) == digv);
    },
    dv: function (T) {
        var M = 0, S = 1;
        for (; T; T = Math.floor(T / 10))
            S = (S + T % 10 * (9 - M++ % 6)) % 11;
        return S ? S - 1 : 'k';
    }
}

const RecommendedForm = () => {
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [rut, setRut] = useState("");
    const [loadedError, setLoadedError] = useState("");
    const [error, setError] = useState(false);
    const [recomendados, setRecomendados] = useState([]);
    const [name, setName] = useState(null);
    const [success, setSuccess] = useState(false);
    const [firstLoad, setFirstLoad] = useState(false);
    const { id_sucursal } = useParams();

    useEffect(() => {
        const getSucursal = async () => {
            try {
                let response = await axios.get('/api/'+id_sucursal)
                setSuccess(response.data.data.success);
                setFirstLoad(true);
            } catch (_err) {
                setSuccess(false);
                setFirstLoad(true);
            }
        }
        getSucursal();
        return () => {
        };
    }, []);

    function checkRut(event) {
        let num = event.target.value.replace(/[^0-9k]/g, "")
        if (num.length > 1) {
            let newNum = [...num];
            newNum.splice(-1, 0, "-")
            setRut(newNum.join(""));
        } else {
            setRut(num)
        }
    }

    const checkRedommended = (event) => {
        event.preventDefault();
        let err = !Fn.validaRut(rut);
        setError(err ? true : false)

        const getRecommendeds = async () => {
            setLoading(true);
            try {
                let response = await axios.get('/api/'+id_sucursal+'/' + rut.replace("-", ""))
                try {
                    setRecomendados(response.data.data.imagenes);
                    setName(response.data.data.nombre);
                    setLoading(false);
                    setLoaded(true);
                } catch (_err2) {
                    setLoading(false);
                    setLoadedError(response.data.error);
                    setLoaded(true);
                    setName("");
                    setRecomendados([]);
                }
            } catch (_err) {
                setRecomendados([]);
                setLoading(false);
                setName("");
                setLoadedError("Error, revisa tu conexión a internet e intenta nuevamente");
                setLoaded(true);
            }
        }
        if (err === false) {
            getRecommendeds();
        }
    }

    return (
        <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 8, background: 'black', paddingTop: 4, minHeight: '100vh' }}>
            {(firstLoad & success)?<Grid container component='form' spacing={3} onSubmit={checkRedommended} >
                    <Grid item xs={12}>
                        <img
                            src="/logo_landing.svg"
                            style={{
                            maxWidth: "80%",
                            }}
                            alt="liquidos.cl"
                        />
                    </Grid>
                <Grid xs={12} sx={{m: 3}}>
                    <Typography variant='h4' component='h2' sx={{ color: '#E6D41E' }}>
                        ¡Hola! Ingresa tu rut para ver qué oferta personalizada tenemos para ti
                    </Typography>
                </Grid>
                <Grid xs={12}>
                    <FormControl required={true} sx={{ minWidth: '70%' }} error={error}>
                        <InputLabel htmlFor='rut' sx={{ color: '#E6D41E' }}>Rut</InputLabel>
                        <Input id='rut' name='rut' autoFocus
                            onChange={checkRut} value={rut}
                        />
                        <FormHelperText sx={{ height: '16px' }}>{error ? 'Rut inválido' : ''}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <LoadingButton
                        type='submit'
                        sx={{ minWidth: '70%', height: '40px' }}
                        loading={loading}
                        variant="contained">{loading ? '' : 'Buscar'}</LoadingButton>
                </Grid>
                {name && <Grid item xs={12}>
                    <Typography variant='h6' component='h3' sx={{ color: '#E6D41E', marginTop: '50px' }}>{name}, esta es tu oferta</Typography>
                    </Grid>}
                {recomendados?.length > 0 ? recomendados.map((obj, i) => (
                    <Grid key={i} item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <ProductCard 
                            url={obj.img_lg} 
                            name={obj.name} 
                            precioSocio={obj.precio_socio} 
                            precioRecomendado={obj.precio_final} 
                            cupon={obj.cupon} 
                            stock={obj.stock}
                            cantidadCupones={obj.coupon_number}
                            fechaInicio={obj.start_date}
                            fechaFin={obj.end_date}
                            dctoMaximo={obj.max_discount}
                            canjesMaximos={obj.coupon_general_number}
                        />
                    </Grid>
                )) : loaded ? <Grid item xs={12}>
                    <Typography variant='h6' component='h3' sx={{ color: '#E6D41E', marginTop: '20px' }}>{loadedError}</Typography>
                    </Grid> : null}
            </Grid>:(firstLoad & !success)?<NotFound/>:null}
        </Container>
    );
}

export default RecommendedForm;
