import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

const moneyFormatter2 = new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
});

const ProductCard = ({ url, name, precioSocio, precioRecomendado, cupon, stock, cantidadCupones, fechaInicio, fechaFin, dctoMaximo, canjesMaximos }) => {
    const initialDate = new Date(fechaInicio);
    const endDate = new Date(fechaFin)
    endDate.setDate(endDate.getDate() - 1);

    const fechaInicialLocal = initialDate.toLocaleString("es-CL", {
        timeZone: "America/Santiago", // Esto toma la zona horaria de Chile, pero en realidad puedes omitirlo y se usará la zona horaria del navegador.
    }).split(",")[0];
    
    const fechaFinalLocal = endDate.toLocaleString("es-CL", {
        timeZone: "America/Santiago", // Esto toma la zona horaria de Chile, pero en realidad puedes omitirlo y se usará la zona horaria del navegador.
    }).split(",")[0];

    return (
        <Card sx={{ maxWidth: 400, fontFamily: 'Oswald' }}>
            <CardMedia
                component="img"
                height={340}
                image={url}
                alt={name}
                sx={{backgroundPosition: 'center center'}}
            />
            <CardContent>
                <Typography gutterBottom variant="h7" component="div" sx={{ textDecorationLine: 'line-through', color: '#8D8D8D', textAlign: 'center', marginLeft: '10px' }}>
                    Precio Socio. {moneyFormatter2.format(precioSocio)}
                </Typography>
                <Typography gutterBottom variant="h5" component="div">
                    {name}
                </Typography>
                <Typography gutterBottom variant="h4" component="div" sx={{ color: 'red', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px' }}>
                    Precio oferta: {moneyFormatter2.format(precioRecomendado)}*
                </Typography>
                <Typography gutterBottom variant="h6" component="div" sx={{ color: '#E6D41E', fontWeight: 'bold', textAlign: 'center', marginLeft: '10px', marginTop: '10px' }}>
                    Usa este cupon al pagar con el vendedor 👉 {cupon}
                </Typography>
                <Typography gutterBottom variant="h8" component="div" sx={{ color: '#8D8D8D', textAlign: 'center', marginLeft: '10px', marginTop: '10px', fontSize:'14px' }}>
                    Stock: {stock}
                </Typography>
                <Typography gutterBottom variant="h9" component="div" sx={{ color: '#8D8D8D', textAlign: 'center', marginLeft: '10px', marginTop: '16px', fontSize:'10px' }}>
                    {"(*)"}Descuento exclusivo para clientes Club Líquidos.cl con un tope de {moneyFormatter2.format(dctoMaximo)} por cupón. Promociones entre {fechaInicialLocal} y {fechaFinalLocal}, máximo {cantidadCupones} cupones por cliente o hasta agotar stock promocional de {canjesMaximos} cupones.
                </Typography>
            </CardContent>
        </Card>
    );
}

export default ProductCard;
